<template>
  <div class="card_style card_style_offline">
    <clientSection
      :clientData="offlineData"
      :base_book_id="base_book_id"
      :rooms="offlineData?.rooms"
      @roomAdded="fetchOfflineHotels"
      :is_portal="isPortal"
    />
    <!-- :supplierOptionsDate="supplier"  -->
    <div class="card width_nav">
      <TabView @tab-click="handleRoomingList">
        <TabPanel :header="$t('available rooms')">
          <availableRooms
            v-if="this.$route.params.id"
            :offlineRooms="offlineData"
            @roomAdded="fetchOfflineHotels"
            :status="offlineData.book_offline_status"
          />
        </TabPanel>
        <TabPanel :header="$t('services')">
          <offlineServices
            :bookStatus="offlineData.book_offline_status"
            v-if="this.$route.params.id"
            :id="offlineData.id"
            :booking_portal_type="offlineData.booking_portal_type"
            :offlineServices="offlineData.services"
            @serviceAdded="fetchOfflineHotels"
          />
          <div class="text-center" v-else>
            <p class="text-danger">{{ $t("must add client data") }}</p>
          </div>
        </TabPanel>
        <TabPanel :header="$t('rooming list')">
          <ListOfRooms
            :rooms="offlineData.rooms"
            :roomList="offlineData.rooming_list"
            v-if="this.$route.params.id"
            :booking_portal_type="offlineData.booking_portal_type"
            ref="roomListRef"
            @roomAdded="fetchOfflineHotels"
          />
          <div class="text-center" v-else>
            <p class="text-danger">{{ $t("must add client data") }}</p>
          </div>
        </TabPanel>
        <TabPanel :header="$t('notes')">
          <NotesSec
            v-if="this.$route.params.id"
            :id="offlineData.id"
            :note="offlineData.notes?.notes"
            :booking_portal_type="offlineData.booking_portal_type"
          />
          <div class="text-center" v-else>
            <p class="text-danger">{{ $t("must add client data") }}</p>
          </div>
        </TabPanel>
      </TabView>
    </div>
    <DailyRestriction :dailyRestrictions="offlineData.daily_restrictions" />
  </div>
</template>

<script>
import clientSection from "./clientSection.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import availableRooms from "@/components/offline_booking/availableRooms.vue";
import ListOfRooms from "../../../components/offline_booking/list_of_rooms.vue";
import NotesSec from "../../../components/offline_booking/NotesSec.vue";
import offlineServices from "@/components/offline_booking/offlineHotelService.vue";
// import paymentMethods from "@/components/offline_booking/paymentMethods.vue";
import axios from "axios";
import DailyRestriction from "@/components/daily_restriction/DailyRestriction.vue";
export default {
  data() {
    return {
      offlineData: {},
      bookId: this.$route.params.id,
      base_book_id: "",
      post_to_account_data: {},
      isPortal: "",
    };
  },

  components: {
    DailyRestriction,
    clientSection,
    // paymentMethods,
    TabView,
    TabPanel,
    availableRooms,
    ListOfRooms,
    offlineServices,
    NotesSec,
  },
  methods: {
    fetchOfflineHotels() {
      axios
        .get(`/offline_booking_hotels/${this.$route.params.id}`)
        .then((res) => {
          this.offlineData = res.data.data;
          this.base_book_id = res.data.data?.base_booking?.id;
          this.isPortal = res.data.data.is_portal;
          // console.log(this.isPortal, "www");
          // console.log(
          //   this.offlineData.rooming_list,
          //   "offlineDatarrrrrrrrrrrrrrr"
          // );
        });
    },
    handleRoomingList() {
      this.$refs.roomListRef.fetchRoomingList();
    },
    clearOfflineData() {
      this.offlineData = {};
      this.base_book_id = "";
      this.isPortal = "";
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchOfflineHotels();
      // this.is_portal = this.portal_id;
    } else if (this.$route.params.id == undefined) {
      this.clearOfflineData();
    }
  },
  watch: {
    "$route.params.id": {
      handler() {
        if (this.$route.params.id != undefined) {
          this.fetchOfflineHotels();
        } else if (this.$route.params.id == undefined) {
          this.clearOfflineData();
        }
      },
      immediate: true, // Optional: Trigger the effect initially
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_hotelsBookOffline.scss";
</style>
