<template>
  <div class="room_list" v-if="roomingList?.length > 0">
    <div class="rooms">
      <div class="row">
        <div
          class="col-12 col-lg-12 d-flex gap-2 align-items-center justify-content-end"
        >
          <router-link
            :to="{
              path: `/${invoice_theme == 'IN01' ? 'book_table' : invoice_theme == 'IN02' ? 'invoice' : 'book_table'}/${bookId}/rooming-list`,
            }"
            type="button"
            class="btn PrimaryButton"
            >{{ $t("print all rooms") }}</router-link
          >
          <button
            class="btn SecondButton"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
            @click="saveChanges(roomingList)"
          >
            {{ $t("save_all") }}
          </button>
        </div>
      </div>
      <div class="room" id="printall">
        <div
          class="table-responsive"
          v-for="(item, index) in roomingList"
          :key="index"
          id="printMe"
        >
          <p class="text-dark">{{ item?.hotel_name }}</p>
          <table class="table">
            <thead>
              <tr class="first_head">
                <th>{{ $t("checkin") }}: {{ item.checkin_date }}</th>
                <th>{{ $t("checkout") }}: {{ item.checkout_date }}</th>
                <th>{{ $t("room_type") }}: {{ item.room_name }}</th>
                <th>{{ $t("view") }}: {{ item.view_type?.title }}</th>
                <th>{{ $t("meal") }}: {{ item.meal_type_name }}</th>
                <th style="width: 150px">
                  <div class="from-group d-flex">
                    <label class="form-label">{{ $t("room number") }}</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="item.room_number"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    />
                  </div>
                </th>
              </tr>
              <tr class="second_head">
                <th>#</th>
                <th>{{ $t("client_name") }}</th>
                <th>{{ $t("notes") }}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody class="position-relative">
              <!-- -->
              <tr>
                <td>
                  <div
                    v-for="(guest, guestindex) in item.guests"
                    :key="guestindex"
                  >
                    {{ $t("guest") }}{{ guestindex + 1 }}
                    <input
                      type="text"
                      class="form-control"
                      v-model="guest.guest_name"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <label for="reservtion" class="form-label">
                      {{ $t("reservation_number") }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="item.booking_number"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    />
                  </div>

                  <!--                  <Textarea-->
                  <!--                    v-model="guest.notes"-->
                  <!--                    class="form-control"-->
                  <!--                    ref="guestNotesRef"-->
                  <!--                    variant="filled"-->
                  <!--                    :autoResize="true"-->
                  <!--                  />-->
                </td>

                <td>
                  <div class="form-group">
                    <label for="reservtion" class="form-label">
                      {{ $t("notes") }}
                    </label>
                    <Textarea
                      v-model="item.notes"
                      class="form-control"
                      ref="guestNotesRef"
                      variant="filled"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                      :autoResize="true"
                    />
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <router-link
                :to="{
                  path: `/${invoice_theme == 'IN01' ? 'book_table' : invoice_theme == 'IN02' ? 'invoice' : 'book_table'}/${bookId}/rooming-list`,
                  query: {
                    booking_room_id: item.id,
                  },
                }"
                type="button"
                class="btn PrimaryButton print action"
                v-if="item.guests"
              >
                {{ $t("print") }}</router-link
              ><button
                type="button"
                class="btn save_changes action"
                @click="saveChanges(item)"
                v-if="item.guests"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              >
                {{ $t("save_changes") }}
              </button>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <p v-else>{{ $t("no_rooms") }}</p>
</template>

<script>
import axios from "axios";

import Textarea from "primevue/textarea";

import Swal from "sweetalert2";
export default {
  props: ["rooms", "roomList", "booking_portal_type"],
  emits: ["roomAdded"],
  data() {
    return {
      bookId: this.$route.params.id,
      roomingList: [],
      guestNotesRefs: [],
      invoice_theme: JSON.parse(localStorage.getItem("user"))?.invoice_theme,
    };
  },
  watch: {
    rooms(newVal) {
      newVal.forEach((room) => {
        let numberOfAdults;
        // console.log(room);
        if (room.guest_number) {
          numberOfAdults = room.guest_number;
        } else {
          numberOfAdults = 2;
        }
        room.guests = [];
        if (this.roomList.length) {
          let hasBooking = false;

          this.roomList.forEach((booking) => {
            if (booking.booking_room_id === room.id) {
              hasBooking = true;
              if (booking.guests.length < numberOfAdults) {
                for (
                  let i = 0;
                  i < numberOfAdults - booking.guests.length;
                  i++
                ) {
                  room.guests.push({
                    guest_name: "",
                    booking_number: "",
                    notes: "",
                  });
                }
              }
            }
          });

          if (!hasBooking) {
            for (let i = 0; i < numberOfAdults; i++) {
              room.guests.push({
                guest_name: "",
                booking_number: "",
                notes: "",
              });
            }
          }
        } else {
          for (let i = 0; i < numberOfAdults; i++) {
            room.guests.push({
              guest_name: "",
              booking_number: "",
              notes: "",
            });
          }
        }
      });
      // console.log(newVal);
    },
    roomList(newVal) {
      newVal.forEach((booking) => {
        booking.guests.forEach((guest) => {
          if (guest.guest_name) {
            this.rooms.forEach((room) => {
              // console.log(booking);
              if (room.id === booking.booking_room_id) {
                room.guests.push({
                  guest_name: guest.guest_name,
                  booking_number: guest.booking_number,
                  notes: guest.notes,
                });
              }
            });
          }
        });
      });
    },
  },
  methods: {
    getGuests(numberOfAdults) {
      const guests = Array.from({ length: numberOfAdults }, () => ({
        guest_name: "",
        booking_number: "",
        notes: "",
      }));
      this.guestsArray = guests;
      return guests;
    },
    fetchRoomingList() {
      if (this.bookId) {
        axios
          .post(`/fetch_rooming_list_by_booking_id`, {
            booking_hotel_id: this.bookId,
          })
          .then(({ data }) => {
            this.roomingList = data.data.data;
            this.roomingList.forEach((roomList) => {
              if (roomList.guests.length < 1) {
                for (let i = 0; i < roomList.adult_count; i++) {
                  roomList.guests.push({
                    guest_name: "",
                  });
                }
              }
            });
            console.log(this.roomingList);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    saveChanges(item) {
      // console.log(item);
      let roomingLists = [];
      if (item.length > 1) {
        roomingLists = item.map((room) => ({
          id: room.id,
          room_number: room.room_number,
          booking_number: room.booking_number,
          notes: room.notes,
          guests: room.guests.map((guest) => ({
            guest_name: guest.guest_name,
          })),
        }));
      } else {
        roomingLists = [
          {
            id: item.id,
            room_number: item.room_number,
            booking_number: item.booking_number,
            notes: item.notes,
            guests: item.guests.map((guest) => ({
              guest_name: guest.guest_name,
            })),
          },
        ];
      }
      // Check if the first guest has a non-empty guest_name

      const data = {
        roomingLists: roomingLists,
      };
      axios
        .post("/updateRoomingListAndGuests", data)
        .then((res) => {
          this.$emit("roomAdded");
          Swal.fire({
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
  mounted() {},

  components: {
    Textarea,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.save_changes,
.print {
  border: none;
  outline: none;
  background-color: #0a909b;
  color: #fff;
  padding: 0.3rem 0.6rem;
  border-radius: 0.5rem;
  margin-right: auto;
  display: block;
  font-size: 12px;
  position: absolute;
  bottom: 15px;
}
.print {
  left: 115px;
}

.table thead tr.first_head th {
  color: #000;
  font-size: 12px;
  text-align: center !important;
  border-left: 1px solid #d0d2d77d;
  &:nth-last-child(1) {
    border-left: 0;
  }
}
.table thead tr.second_head th {
  background-color: #d0d2d74f;
  color: #656b78;
  font-family: "regular";
  font-size: 12px;
  border-left: 1px solid #d0d2d77d;
  &:nth-last-child(1),
  &:nth-last-child(2) {
    border-left: 0;
  }
}

.table tbody tr td {
  background-color: #d0d2d72b;
  border-left: 1px solid #d0d2d77d;
  &:nth-last-child(1),
  &:nth-last-child(2) {
    border-left: 0;
  }
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  .form-control {
    width: 80%;
  }
}
textarea {
  resize: both !important;
  //width: 100%;
}
</style>
