<template>
  <!-- component with nested async dependencies -->
  <div class="availableRooms" v-if="loading">
    <div class="row">
      <div class="col-3" v-for="i in 8" :key="i">
        <Skeleton height=".5rem" width="4rem" class="mb-1"></Skeleton>
        <Skeleton width="8rem" height="2rem" class="mb-1"></Skeleton>
      </div>
    </div>
  </div>

  <div class="availableRooms" v-else>
    <button
      class="btn add_room"
      style="width: fit-content"
      @click="show = !show"
      v-if="addedRooms.length > 0 && show == false"
      :disabled="
        booking_portal_type === 2
          ? true
          : booking_portal_type === 1
            ? true
            : booking_portal_type === 3
              ? true
              : false
      "
    >
      {{ $t("add_room") }}
    </button>
    <form v-if="addedRooms.length == 0 || show" @submit.prevent="addRoom()">
      <div class="userData d-flex align-items-start">
        <div class="form-group">
          <label class="form-label">{{ $t("hotel") }}</label>
          <Multiselect
            v-model="hotelValue"
            :options="hotels"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            :custom-label="formatHotelLabel"
            @select="fetchRooms()"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          >
          </Multiselect>
        </div>
        <div class="form-group">
          <div class="d-flex align-items-center justify-content-between mt-1">
            <label class="form-label" style="font-size: 0.6rem !important">
              {{ $t("supplier_type") }}</label
            >
            <AddHotelSupplier
              :hotel="hotelValue"
              @submit="fetchOffline"
              v-if="supplier.title == 'Hotel Director'"
            />
          </div>
          <Multiselect
            v-model="supplier"
            :options="optionsSupplies"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @update:model-value="getContracts()"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          >
          </Multiselect>
        </div>

        <!-- supplier == 'Supplier' -->
        <div
          class="form-group col-12 col-md-2"
          v-if="supplier.title == 'Supplier'"
        >
          <div class="d-flex justify-content-between">
            <label class="">{{ $t("Supplier") }}</label>
            <supllierDialog @supplierAdded="fetchSupplires" />
          </div>
          <Multiselect
            v-model="supplierValue"
            :options="suppliers"
            label="name"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('suppliers')"
            select-label=""
            :hide-selected="true"
            :custom-label="formatSupplierLabel"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          >
          </Multiselect>
        </div>
        <!-- supplier == 'Contract' -->
        <div
          class="form-group col-12 col-md-2"
          v-if="supplier.title == 'Contract'"
        >
          <label class="form-label">{{ $t("contract_name") }}</label>
          <multiselect
            v-model="contractValue"
            :options="contracts"
            label="name"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @update:model-value="updateContractValue(contractValue)"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          ></multiselect>
        </div>

        <!-- supplier == 'Contract' -->
        <div
          class="form-group col-12 col-md-2"
          v-if="supplier.title == 'Contract'"
        >
          <label class="form-label">Rate Code</label>
          <multiselect
            v-model="rateCode"
            :options="rateCodes"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @update:model-value="fetchRoomByRateCode(rateCode)"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          ></multiselect>
        </div>

        <div class="form-group col-12 col-md-2">
          <label class="form-label">{{ $t("entrance_date") }}</label>
          <datepicker
            v-model="checkin"
            lang="ar"
            format="yyyy-MM-dd"
            :placeholder="$t('choose')"
            :disabled-dates="{
              to: new Date(offlinebookingCheckIn),
              from: new Date(offlinebookingCheckOut),
            }"
            @input="updateDayNumber"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          ></datepicker>
        </div>

        <div class="form-group col-12 col-md-2">
          <label class="form-label">{{ $t("checkout_date") }}</label>
          <datepicker
            v-model="checkout"
            lang="ar"
            format="yyyy-MM-dd"
            :placeholder="$t('choose')"
            :disabled-dates="{
              to: new Date(offlinebookingCheckIn),
              from: new Date(offlinebookingCheckOut),
            }"
            @input="updateDayNumber"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          ></datepicker>
        </div>

        <div class="form-group col-12 col-md-1">
          <label class="form-label">{{ $t("number_of_nights") }}</label>
          <input
            type="number"
            class="form-control"
            id="exampleFormControlInput1"
            v-model="day_number"
            disabled
          />
        </div>
        <div class="form-group col-12 col-md-2">
          <label class="form-label"> {{ $t("supplier_option_date") }}</label>
          <datepicker
            v-model="optionDate"
            lang="ar"
            format="yyyy-MM-dd"
            :placeholder="$t('choose')"
            :disabledDates="disabledDates"
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          >
          </datepicker>
        </div>

        <div class="form-group col-3 col-md-1">
          <label class="form-label"> {{ $t("time") }}</label>
          <Calendar
            v-model="optionTime"
            showIcon
            iconDisplay="input"
            timeOnly
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          >
            <template #inputicon="{ clickCallback }">
              <i class="pi pi-clock" @click="clickCallback" />
            </template>
          </Calendar>
        </div>

        <div
          class="form-check form-switch col-12 col-md-2 d-flex w-75 my-2"
          v-if="supplier.title != 'Contract'"
        >
          <label for="toggle_form_data_tax" class="text_design mb-0">
            {{ $t("Taxes included") }}
          </label>
          <input
            class="form-check-input switch mx-1"
            type="checkbox"
            id="toggle_form_data_tax"
            v-model="toggle_form_tax"
            checked
            :disabled="
              booking_portal_type === 2
                ? true
                : booking_portal_type === 1
                  ? true
                  : booking_portal_type === 3
                    ? true
                    : false
            "
          />
          <label for="toggle_form_data_tax" class="text_design mb-0">
            {{ $t("Taxes not included") }}
          </label>
        </div>
      </div>
      <div class="inputs row">
        <div class="inputsData col-12 col-md-11 row">
          <div class="col-12 col-md-2">
            <div class="form-group form_room_select">
              <div class="d-flex algin-items-center justify-content-between">
                <label class="form-label">
                  {{ $t("room name") }}
                </label>
                <div
                  class="form-check form-switch col-12 col-md-2 d-flex w-75 justify-content-end"
                  v-if="supplier.title == 'Contract'"
                >
                  <label
                    for="toggle_form_type_price"
                    class="text_design mb-0"
                    style="font-size: 12px"
                  >
                    b2b
                  </label>
                  <input
                    class="form-check-input switch mx-1"
                    type="checkbox"
                    id="toggle_form_type_price"
                    v-model="type_price_contract"
                    checked
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                  />
                  <!-- @change="fetchRoomByRateCode(rateCode)" -->
                  <label
                    for="toggle_form_type_price"
                    class="text_design mb-0"
                    style="font-size: 12px"
                  >
                    b2c
                  </label>
                </div>
              </div>
              <multiselect
                v-if="supplier.title == 'Contract'"
                class="w-100"
                v-model="roomByRateCodeValue"
                :options="roomByRateCode"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              ></multiselect>
              <multiselect
                v-else
                class="w-100 rooms_select"
                v-model="roomValue"
                :options="rooms"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              ></multiselect>
            </div>
          </div>
          <div class="col-12 col-md-3 col-lg-1">
            <div class="form-group pax">
              <label class="form-label"> pax </label>
              <input
                type="number"
                :placeholder="roomValue?.max_adt ?? 1"
                value="1"
                min="1"
                class="form-control"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
                v-model="guestNumber"
              />
            </div>
          </div>
          <div class="col-12 col-md-3 col-lg-1">
            <div class="form-group count">
              <label class="form-label">{{ $t("number_of_rooms") }} </label>
              <input
                type="number"
                min="1"
                value="1"
                class="form-control"
                v-model="roomNumber"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
                :max="
                  supplier.title == 'Contract'
                    ? contracts[indexOfContract]?.max_count
                    : null
                "
              />
              <span
                class="text-danger fs-12"
                v-if="roomNumber > contracts[indexOfContract]?.max_count"
              >
                عدد الغرف المتاحة :
                {{ contracts[indexOfContract]?.max_count }}
              </span>
            </div>
          </div>

          <div
            class="col-12 col-md-3 col-lg-2"
            v-if="supplier.title != 'Contract'"
          >
            <div class="form-group position-relative">
              <div class="d-flex align-items-center justify-content-between">
                <label class="form-label px-2 mb-0"> {{ $t("meal") }} </label>
                <div
                  class="form-check form-switch d-flex align-items-center justify-content-end"
                >
                  <input
                    class="form-check-input switch mx-1"
                    type="checkbox"
                    id="toggle_form_data_meal"
                    v-model="toggle_form_meal"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                  />
                  <label for="toggle_form_data_meal" class="text_design mb-0">
                    {{
                      toggle_form_meal
                        ? $t("contain_meal")
                        : $t("not_contain_meal")
                    }}
                  </label>
                </div>
              </div>
              <Multiselect
                v-model="mealsValue"
                :options="meals"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :hide-selected="true"
                :placeholder="$t('meals')"
                select-label=""
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              >
              </Multiselect>
              <span
                v-if="mealsValue && mealsValue.is_default !== 1"
                @click="removeMeals"
                class="removeMeals text-danger mx-1 position-absolute"
                ><i class="fa-regular fa-trash-can"></i
              ></span>
            </div>
          </div>
          <div
            class="col-12 col-md-3 col-lg-2"
            v-if="supplier.title == 'Contract'"
          >
            <div class="form-group position-relative">
              <div class="d-flex align-items-center justify-content-between">
                <label class="form-label"> {{ $t("meal") }} </label>
                <div
                  class="form-check form-switch d-flex align-items-center justify-content-end"
                >
                  <input
                    class="form-check-input switch mx-1"
                    type="checkbox"
                    id="toggle_form_data_meal"
                    v-model="toggle_form_meal"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                  />
                  <label for="toggle_form_data_meal" class="text_design mb-0">
                    {{
                      toggle_form_meal
                        ? $t("contain_meal")
                        : $t("not_contain_meal")
                    }}
                  </label>
                </div>
              </div>
              <Multiselect
                v-model="mealRateCode"
                :options="mealsRateCode"
                label="meal_type_title"
                track-by="meal_type_id"
                :clear-on-select="true"
                :hide-selected="true"
                :placeholder="$t('meals')"
                select-label=""
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              >
              </Multiselect>
              <button
                v-if="mealRateCode"
                @click="removeMeals"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
                class="removeMeals text-danger mx-1 position-absolute"
              >
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </div>
          </div>

          <div
            class="col-12 col-md-2 col-lg-1"
            v-if="!toggle_form_meal && mealsValue.is_default !== 1"
          >
            <div class="form-group">
              <label class="form-label"> {{ $t("تكلفة الوجبة") }}</label>
              <input
                type="text"
                name=""
                v-model="mealCost"
                class="form-control"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              />
            </div>
          </div>
          <div
            class="col-12 col-md-3 col-lg-1"
            v-if="!toggle_form_meal && mealsValue.is_default !== 1"
          >
            <div class="form-group">
              <label class="form-label"> {{ $t("بيع الوجبة") }} </label>
              <input
                type="text"
                name=""
                v-model="mealSelling"
                class="form-control"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              />
            </div>
          </div>

          <div class="d-flex w-25 flex-column align-items-center">
            <div class="w-100 d-flex gap-3">
              <div v-if="supplier.title != 'Contract'">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t("cost of room") }}
                  </label>
                  <input
                    type="text"
                    v-model="roomCost"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                    class="form-control"
                  />
                </div>
              </div>
              <div v-if="supplier.title != 'Contract'">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t("selling of room") }}
                  </label>
                  <input
                    type="text"
                    v-model="roomSelling"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                    class="form-control"
                  />
                </div>
              </div>
              <div v-else>
                <div class="form-group">
                  <div class="d-flex">
                    <label for="" class="form-label">{{
                      $t("custom_contract_price")
                    }}</label>
                    <div
                      class="form-check form-switch d-flex align-items-center justify-content-end"
                    >
                      <input
                        class="form-check-input switch mx-1"
                        type="checkbox"
                        id="toggle_form_data_meal"
                        v-model="toggle_custom_price"
                        :disabled="
                          booking_portal_type === 2
                            ? true
                            : booking_portal_type === 1
                              ? true
                              : booking_portal_type === 3
                                ? true
                                : false
                        "
                      />
                    </div>
                    <label for="" class="form-label">{{
                      $t("contract_price")
                    }}</label>
                  </div>
                  <input
                    v-if="toggle_custom_price"
                    type="text"
                    v-model="roomSelling"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <p
              v-if="parseFloat(roomSelling) < parseFloat(roomCost)"
              class="font-weight-bold text-danger mt-2"
              style="width: max-content"
            >
              {{
                $t("Note: The selling cost is less than the purchasing cost")
              }}
            </p>
          </div>
          <div class="col-12 col-md-3 col-lg-1">
            <p class="title_price" style="width: max-content">
              {{ $t("total_cost_offline") }}
            </p>
            <p class="price">
              {{
                roomByRateCodeValue && supplier.title == "Contract"
                  ? roomByRateCodeValue.total_cost * roomNumber +
                    (mealRateCode.cost ? mealRateCode.cost : 0)
                  : totalPriceOffline
              }}
            </p>
          </div>
          <div class="col-12 col-md-3 col-lg-1">
            <p class="title_price" style="width: max-content">
              {{ $t("total_selling_offline") }}
            </p>
            <!-- selling_type => B2B = 1; B2C = 2; -->
            <p class="price">
              {{
                roomByRateCodeValue && supplier.title == "Contract"
                  ? type_price_contract == true
                    ? " (B2B) " +
                      roomByRateCodeValue.total_selling_price_b2b * roomNumber +
                      (mealRateCode.b2b_price ? mealRateCode.b2b_price : 0)
                    : " (B2C) " +
                      roomByRateCodeValue.total_selling_price_b2c * roomNumber +
                      (mealRateCode.b2c_price ? mealRateCode.b2c_price : 0)
                  : totalSellingOffline
              }}
            </p>
          </div>
          <Accordion
            class="col-12"
            :activeIndex="0"
            v-if="roomByRateCodeValue?.days.length > 0"
          >
            <AccordionTab :header="$t('availablity days')">
              <div class="table-responsive">
                <table class="table table-bordered mb-0 overflow-x-auto">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t("date") }}</th>
                      <th
                        scope="col"
                        v-for="(day, item) in roomByRateCodeValue.days"
                        :key="item"
                        :style="{
                          'background-color': day.is_weekend
                            ? '#0a909b'
                            : '#535353',
                        }"
                      >
                        <!-- <div class="quantity">
                                  <button
                                    class="btn minus"
                                    type="button"
                                    @click="minusQuantity(index)"
                                  >
                                    <i class="fa-solid fa-minus"></i>
                                  </button>
                                  <span class="number">{{ room.count }}</span>
                                  <button
                                    class="btn add"
                                    type="button"
                                    @click="addQuantity(index)"
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </button>
                                </div> -->
                        <div
                          class="d-flex align-items-baseline justify-content-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-white' : 'text-white',
                              ]"
                            >
                              {{ formatDate(day.date) }}
                            </p>

                            <p
                              class="hijri mb-0"
                              :class="[
                                day.is_weekend ? 'text-white' : 'text-white',
                              ]"
                            >
                              {{ updateHijriDate(new Date(day.date)) }}
                            </p>
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-white' : 'text-white',
                              ]"
                            >
                              {{ getWeekday(new Date(day.date)) }}
                            </p>
                          </div>
                          <i
                            class="fa-solid fa-circle fa-2xs text-white"
                            v-if="day.has_edited == '1'"
                          ></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">{{ $t("available") }}</td>
                      <td
                        v-for="(day, item) in roomByRateCodeValue.days"
                        :key="item"
                      >
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          disabled
                          v-model="day.available_stock"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{{ $t("available after minus") }}</td>
                      <td
                        v-for="(day, item) in roomByRateCodeValue.days"
                        :key="item"
                      >
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          disabled
                          v-model="day.available_stock_without_minus"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        <div
          class="col-md-1 col-12 d-flex align-items-center justify-content-end gap-3"
        >
          <button
            class="btn sumbit-btn"
            type="submit"
            :disabled="
              !hotelSubmit ||
              status == 3 ||
              posted === 1 ||
              loader ||
              booking_portal_type === 2
                ? true
                : false
            "
          >
            <!-- :disabled="isAllDataValid" -->
            <i class="fa-solid fa-circle-notch fa-spin" v-if="loader"></i>
            <span v-else>
              {{ $t("save") }} <i class="fa-regular fa-circle-check"></i>
            </span>
          </button>
        </div>
      </div>
      <!-- {{ supplier.title }} -->
    </form>
    <hr class="my-1" />
    <Accordion multiple v-if="addedRooms.length > 0" :activeIndex="0">
      <AccordionTab
        :header="
          room?.hotel_room_view_title != null
            ? room?.hotel_room_view_title +
              ' - ' +
              '(' +
              room?.hotel_title +
              ')'
            : room.room.title + ' - ' + '(' + room?.hotel_title + ')'
        "
        v-for="(room, index) in addedRooms"
        :key="index"
      >
        <div class="update-offline-hotel mt-4">
          <form @submit.prevent="updateRoom(index)">
            <div
              class="title d-flex text-start align-items-start flex-column flex-lg-row w-100"
            >
              <div>
                <h4>{{ $t("suppliers") }}</h4>
                <div class="w-100 client-info">
                  <h5>{{ room.supplier?.title }}</h5>
                  <h5>{{ $t("Hotel") }}: {{ room.hotel_title }}</h5>
                  <h5>
                    {{ room?.contract?.title }}
                    {{ room?.season_rate_code?.title }}
                  </h5>
                  <div class="d-flex w-100 align-items-center gap-1 mb-2">
                    <span class="w-100">{{ room.supplier_type }}</span>
                    <span
                      class="arrive_status"
                      :class="
                        room?.attend_status == 1
                          ? 'unknow'
                          : room?.attend_status == 2
                            ? 'checkin'
                            : room?.attend_status == 3
                              ? 'in_house'
                              : 'checkout'
                      "
                    >
                      {{
                        room?.attend_status == 1
                          ? $t("unknow")
                          : room?.attend_status == 2
                            ? $t("checkin")
                            : room?.attend_status == 3
                              ? $t("in_house")
                              : $t("checkout")
                      }}
                    </span>
                  </div>
                  <Dropdown
                    v-model="room.book_status"
                    :options="items"
                    editable
                    optionLabel="title"
                    :placeholder="$t('Select a Status')"
                    class="w-full md:w-14rem"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? false
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                    @update:modelValue="
                      confirmRoom(
                        room.id,
                        room.book_status.status,
                        room.supplier_option_date,
                        room.supplier_option_time,
                        room,
                      )
                    "
                  >
                    <template #value="slotProps">
                      <div class="flex align-items-center">
                        <div>{{ slotProps.title }}</div>
                        <i class="fa-regular fa-rectangle-list"></i>
                      </div>
                    </template>
                  </Dropdown>
                  <!-- {{ room.confirm_room.status }} -->
                </div>
              </div>

              <div class="flex-grow-1">
                <h4 v-tooltip="$t('room_detail')">
                  {{ maxWordTruncate($t("room_detail"), 10) }}
                </h4>
                <div class="w-100 room-ditail">
                  <p>
                    {{ $t("room") }} :
                    <span>{{
                      room?.hotel_room_view_title != null
                        ? room?.hotel_room_view_title
                        : room.room.title
                    }}</span>
                  </p>
                  <p v-if="room.meal_type">
                    {{ $t("meal") }} : <span> {{ room.meal_type?.title }}</span>
                  </p>
                </div>
              </div>

              <div>
                <h4>{{ $t("Check_In_Check_Out") }}</h4>
                <div class="w-100">
                  <datepicker
                    v-model="room.check_in_date"
                    class="col-lg-3 mb-3"
                    lang="ar"
                    format="yyyy-MM-dd"
                    :placeholder="new Date().toLocaleDateString('en-US')"
                    :disabled-dates="{
                      to: new Date(offlinebookingCheckIn),
                      from: new Date(offlinebookingCheckOut),
                    }"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                    @input="updateFilteredDays(index)"
                  ></datepicker>
                  <datepicker
                    v-model="room.check_out_date"
                    class="col-lg-3 mb-3"
                    lang="ar"
                    format="yyyy-MM-dd"
                    :placeholder="new Date().toLocaleDateString('en-US')"
                    :disabled-dates="{
                      to: new Date(offlinebookingCheckIn),
                      from: new Date(offlinebookingCheckOut),
                    }"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                    @input="updateFilteredDays(index)"
                  ></datepicker>
                </div>
              </div>

              <div>
                <h4 v-tooltip="$t('Hotel con. - Supplier reference')">
                  {{
                    maxWordTruncate($t("Hotel con. - Supplier reference"), 10)
                  }}
                </h4>

                <div class="col mt-5">
                  <div class="form-group">
                    <div
                      class="w-100 d-flex align-items-start gap-2 flex-column"
                    >
                      <div class="form-group w-100 px-2 my-1 mx-0">
                        <label class="typo__label custom_label profit">
                          Hot con.</label
                        >
                        <input
                          type="text"
                          name=""
                          v-model="room.hotel_reference"
                          class="form-control w-100"
                          :disabled="
                            booking_portal_type === 2
                              ? true
                              : booking_portal_type === 1
                                ? false
                                : booking_portal_type === 3
                                  ? true
                                  : false
                          "
                        />
                      </div>
                      <div class="form-group w-100 px-2 my-1 mx-0">
                        <label class="typo__label custom_label profit">
                          sup ref.
                        </label>
                        <input
                          type="text"
                          name=""
                          v-model="room.supplier_reference"
                          class="form-control w-100"
                          :disabled="
                            booking_portal_type === 2
                              ? true
                              : booking_portal_type === 1
                                ? false
                                : booking_portal_type === 3
                                  ? true
                                  : false
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h4>{{ $t("number_of_rooms") }}</h4>
                <div class="w-100">
                  <div class="px-2">
                    <input
                      type="number"
                      min="1"
                      v-model="room.room_number"
                      class="form-control w-100"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    />
                    <label class="typo__label custom_label profit">
                      {{ $t("number_of_guest") }}</label
                    >
                    <div class="w-100">
                      <input
                        type="number"
                        min="1"
                        v-model="room.guest_number"
                        class="form-control w-100"
                        :disabled="
                          booking_portal_type === 2
                            ? true
                            : booking_portal_type === 1
                              ? true
                              : booking_portal_type === 3
                                ? true
                                : false
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div>
                <h4>عدد النزلاء</h4>
                <div class="w-100">
                  <div class="px-2">
                    <label class="form-label"> pax </label>
                    <input
                      type="number"
                      min="1"
                      v-model="room.guest_number"
                      class="form-control w-100"
                    />
                  </div>
                </div>
              </div> -->
              <div>
                <h4>{{ $t("cost of room") }}</h4>
                <div class="w-100">
                  <div class="form-group px-2">
                    <label class="typo__label custom_label profit">
                      {{ $t("cost of room") }}
                    </label>
                    <input
                      type="text"
                      name=""
                      @input="updateDaysCost(room)"
                      v-model="room.room_cost"
                      class="form-control"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? false
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    />
                  </div>
                  <div
                    class="form-group px-2"
                    v-if="room.meal_type != null && room.meal_cost > 0"
                  >
                    <label class="typo__label custom_label profit">
                      {{ $t("meal_cost") }}
                    </label>
                    <input
                      type="text"
                      name=""
                      v-model="room.meal_cost"
                      class="form-control"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    />
                  </div>
                </div>
              </div>
              <div>
                <h4>{{ $t("selling of room") }}</h4>
                <div class="w-100">
                  <div class="form-group px-2">
                    <label class="typo__label custom_label profit">
                      {{ $t("selling of room") }}
                    </label>
                    <input
                      type="text"
                      name=""
                      @input="updateDaysSelling(room)"
                      v-model="room.room_selling"
                      class="form-control"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? false
                              : false
                      "
                    />
                  </div>
                  <div
                    class="form-group px-2"
                    v-if="room.meal_type != null && room.meal_selling > 0"
                  >
                    <label class="typo__label custom_label profit">
                      {{ $t("meal_selling") }}
                    </label>
                    <input
                      type="text"
                      name=""
                      v-model="room.meal_selling"
                      class="form-control"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? true
                            : booking_portal_type === 3
                              ? false
                              : false
                      "
                    />
                  </div>
                </div>
              </div>
              <div>
                <h4>Option date</h4>
                <div class="w-100 date_aboulate_left px-2">
                  <div>
                    <datepicker
                      class="mb-3"
                      lang="ar"
                      format="yyyy-MM-dd"
                      :placeholder="
                        room.supplier_option_date == null
                          ? ''
                          : room.supplier_option_date
                      "
                      v-model="room.supplier_option_date"
                      :disabledDates="{
                        to: new Date(
                          new Date().setDate(new Date().getDate() - 1),
                        ),
                        from: new Date(
                          new Date(room.check_in_date).setDate(
                            new Date(room.check_in_date).getDate() - 1,
                          ),
                        ),
                      }"
                      :disabled="
                        booking_portal_type === 2
                          ? true
                          : booking_portal_type === 1
                            ? false
                            : booking_portal_type === 3
                              ? true
                              : false
                      "
                    ></datepicker>
                  </div>
                  <Calendar
                    iconDisplay="input"
                    timeOnly
                    hourFormat="12"
                    v-model="room.supplier_option_time"
                    :disabled="
                      booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                  >
                  </Calendar>
                </div>
              </div>
              <div>
                <h4>{{ $t("actions") }}</h4>
                <div
                  class="w-100 d-flex align-items-center justify-content-center gap-2 actions_rooms_offline"
                >
                  <button
                    class="btn btn-danger"
                    type="button"
                    @click="DeleteAlert(index)"
                    v-tooltip="$t('delete')"
                    :disabled="
                      status == 3 || posted === 1 || booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? true
                          : booking_portal_type === 3
                            ? true
                            : false
                    "
                  >
                    <i class="fa-solid fa-trash"></i>
                  </button>
                  <button
                    class="btn btn-success mt-0 fs-6"
                    v-tooltip="$t('save')"
                    type="submit"
                    :disabled="
                      status == 3 || posted === 1 || booking_portal_type === 2
                        ? true
                        : booking_portal_type === 1
                          ? false
                          : booking_portal_type === 3
                            ? false
                            : false
                    "
                  >
                    <i class="fa-solid fa-floppy-disk"></i>
                  </button>
                  <!-- <button
                    class="btn btn-info mt-0 m-0"
                    @click="confirmRoom(room.id)"
                    v-tooltip="$t('confirm')"
                    :disabled="status == 3"
                    type="button"
                  >
                    <i class="fa-regular fa-circle-check"></i>
                  </button> -->
                  <editRoom
                    @fetchData="editRoom"
                    :room="room"
                    :booking_portal_type="booking_portal_type"
                  />
                  <button
                    :class="['btn', 'mt-0', 'fs-6', 'btn-danger']"
                    v-if="
                      room.confirm_room == 'Confirmed' &&
                      new Date(room.check_in_date) <= new Date()
                    "
                    type="button"
                    v-tooltip.top="$t('not_arrive')"
                    @click="noShowRoom(room, 0)"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <Accordion>
              <AccordionTab>
                <template #header>
                  <span class="flex align-items-center gap-2 w-full">
                    <span class="white-space-nowrap">{{
                      $t("show_details")
                    }}</span>
                  </span>
                </template>
                <div class="details">
                  <div
                    class="headline d-flex align-items-center justify-content-between py-3 flex-column flex-md-row text-dark flex-wrap"
                  >
                    <div
                      class="form-check form-switch d-flex align-items-center justify-content-end"
                    >
                      <label for="night_all" class="text_design mb-0">{{
                        $t("night")
                      }}</label>
                      <input
                        class="form-check-input switch mx-1"
                        type="checkbox"
                        id="night_all"
                        v-model="toggle_form_data"
                        :disabled="
                          booking_portal_type === 2
                            ? true
                            : booking_portal_type === 1
                              ? true
                              : booking_portal_type === 3
                                ? true
                                : false
                        "
                      />
                      <label for="night_all" class="text_design mb-0">{{
                        $t("all")
                      }}</label>
                    </div>
                    <div class="check-boxes d-flex align-items-center">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="all"
                          :id="`all_${index}`"
                          v-model="addedRooms[index].selectedRadio"
                          :name="`allFilter_${index}`"
                          :disabled="
                            booking_portal_type === 2
                              ? true
                              : booking_portal_type === 1
                                ? true
                                : booking_portal_type === 3
                                  ? true
                                  : false
                          "
                        />
                        <label class="form-check-label" :for="`all_${index}`">
                          {{ $t("all") }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="weekday"
                          :id="`weekday_${index}`"
                          v-model="addedRooms[index].selectedRadio"
                          :name="`weekdayFilter_${index}`"
                          :disabled="
                            booking_portal_type === 2
                              ? true
                              : booking_portal_type === 1
                                ? true
                                : booking_portal_type === 3
                                  ? true
                                  : false
                          "
                        />
                        <label
                          class="form-check-label"
                          :for="`weekday_${index}`"
                        >
                          {{ $t("Weekday") }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="weekend"
                          :id="`weekend_${index}`"
                          v-model="addedRooms[index].selectedRadio"
                          :name="`weekendFilter_${index}`"
                          :disabled="
                            booking_portal_type === 2
                              ? true
                              : booking_portal_type === 1
                                ? true
                                : booking_portal_type === 3
                                  ? true
                                  : false
                          "
                        />
                        <label
                          class="form-check-label"
                          :for="`weekend_${index}`"
                        >
                          {{ $t("Weekend") }}
                        </label>
                      </div>
                    </div>
                    <div class="form-group d-flex mb-0 align-items-center">
                      <label class="form-label mb-0" style="width: 36%">{{
                        $t("cost_price")
                      }}</label>
                      <input
                        type="text"
                        name=""
                        :disabled="
                          !addedRooms[index].selectedRadio ||
                          booking_portal_type === 2
                            ? true
                            : booking_portal_type === 1
                              ? true
                              : booking_portal_type === 3
                                ? true
                                : false
                        "
                        v-model="roomsCost"
                        class="form-control"
                        @input="updateAllRoomsCost(index)"
                      />
                    </div>
                    <div class="form-group d-flex mb-0 align-items-center">
                      <label class="form-label mb-0" style="width: 36%">{{
                        $t("final_price")
                      }}</label>
                      <input
                        type="text"
                        name=""
                        :disabled="
                          !addedRooms[index].selectedRadio ||
                          booking_portal_type === 2
                            ? true
                            : booking_portal_type === 1
                              ? true
                              : booking_portal_type === 3
                                ? true
                                : false
                        "
                        v-model="roomsSelling"
                        class="form-control"
                        @input="updateAllRoomsSelling(index)"
                      />
                    </div>
                  </div>
                  <div class="days">
                    <div class="head d-flex align-items-center">
                      <div class="py-3 text-center" style="min-width: 130px">
                        <h4>{{ $t("date") }}</h4>
                      </div>
                      <!-- <p class="text-black">{{ room.filteredDays }}</p> -->
                      <div
                        class="d-flex w-100"
                        v-if="addedRooms[index].filteredDays?.length > 0"
                      >
                        <div
                          class="py-2 text-center date_head"
                          :class="day.is_weekend == 1 ? 'bg-info' : ''"
                          v-for="day in addedRooms[index].filteredDays"
                          :key="day"
                          style="min-width: 110px"
                        >
                          <h4>{{ day.day }}</h4>
                          <h4>{{ day.day_name }}</h4>
                        </div>
                      </div>
                      <div class="d-flex w-100" v-else>
                        <div
                          class="py-2 text-center date_head"
                          :class="day.is_weekend == 1 ? 'bg-info' : ''"
                          v-for="day in addedRooms[index]
                            .offline_booking_room_days"
                          :key="day"
                          style="min-width: 110px"
                        >
                          <h4>{{ day.day }}</h4>
                          <h4>{{ day.day_name }}</h4>
                          <span class="hijri">{{
                            updateHijriDate(new Date(day.day))
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="body d-flex align-items-center">
                      <div
                        class="py-2 px-2 text-center selling_price_input"
                        style="min-width: 130px"
                      >
                        <h4 class="py-2">{{ $t("price") }}</h4>
                      </div>
                      <div
                        class="d-flex w-100"
                        v-if="addedRooms[index].filteredDays.length > 0"
                      >
                        <div
                          class="py-2 px-2 text-center cost_price_input"
                          v-for="day in addedRooms[index].filteredDays"
                          :key="day"
                          style="min-width: 110px; width: 110px"
                        >
                          <input
                            type="number"
                            v-model="day.room_cost"
                            class="form-control w-100"
                            :disabled="
                              booking_portal_type === 2
                                ? true
                                : booking_portal_type === 1
                                  ? false
                                  : booking_portal_type === 3
                                    ? true
                                    : false
                            "
                          />
                        </div>
                      </div>
                      <div class="d-flex w-100" v-else>
                        <div
                          class="py-2 px-2 text-center"
                          v-for="day in addedRooms[index]
                            .offline_booking_room_days"
                          :key="day"
                          style="min-width: 110px; width: 110px"
                        >
                          <input
                            type="number"
                            min="0"
                            v-model="day.room_cost"
                            class="form-control w-100"
                            :disabled="
                              booking_portal_type === 2
                                ? true
                                : booking_portal_type === 1
                                  ? false
                                  : booking_portal_type === 3
                                    ? true
                                    : false
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="body d-flex align-items-center w-100">
                      <div
                        class="py-2 px-2 text-center"
                        style="min-width: 130px"
                      >
                        <h4 class="py-2">{{ $t("selling") }}</h4>
                      </div>
                      <div
                        class="d-flex flex-grow-1"
                        v-if="addedRooms[index].filteredDays.length > 0"
                      >
                        <div
                          class="py-2 px-2 text-center"
                          v-for="day in addedRooms[index].filteredDays"
                          :key="day.id"
                          style="min-width: 110px; width: 110px"
                        >
                          <input
                            type="number"
                            v-model="day.room_selling"
                            class="form-control w-100"
                            :disabled="
                              booking_portal_type === 2
                                ? true
                                : booking_portal_type === 1
                                  ? true
                                  : booking_portal_type === 3
                                    ? false
                                    : false
                            "
                          />
                        </div>
                      </div>
                      <div class="d-flex w-100" v-else>
                        <div
                          class="py-2 px-2 text-center"
                          v-for="day in addedRooms[index]
                            .offline_booking_room_days"
                          :key="day"
                          style="min-width: 110px; width: 110px"
                        >
                          <input
                            type="number"
                            min="0"
                            v-model="day.room_selling"
                            class="form-control w-100"
                            :disabled="
                              booking_portal_type === 2
                                ? true
                                : booking_portal_type === 1
                                  ? true
                                  : booking_portal_type === 3
                                    ? false
                                    : false
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </form>
          <hr />
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import Datepicker from "vuejs3-datepicker";
// import moment from "moment";
import Calendar from "primevue/calendar";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Datepicker from "vuejs3-datepicker";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
// import supllierDialog from "@/components/Dialogs/supplierDialog.vue";
import supllierDialog from "../Dialogs/supplierDialog.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddHotelSupplier from "@/components/Dialogs/addHotelSupplier.vue";

// import Menu from "primevue/menu";

import Dropdown from "primevue/dropdown";

import Skeleton from "primevue/skeleton";
import editRoom from "@/components/Dialogs/editRoomOffline.vue";

export default {
  props: ["hotel", "data", "offlineRooms", "status"],
  emits: ["roomAdded"],
  components: {
    Multiselect,
    Calendar,
    Accordion,
    Dropdown,
    Datepicker,
    AccordionTab,
    Skeleton,
    supllierDialog,
    AddHotelSupplier,
    editRoom,
  },
  data() {
    return {
      loading: true,
      mealsRateCode: [],
      posted: "",
      mealRateCode: "",
      show: false,
      type_price_contract: true,
      hotel_reference: "",
      supplier_reference: "",
      menu: false,
      // showLoading: false,
      day_number: 0,
      toggle_form_tax: true, // Assuming this is your checkbox state
      tax: 1, // Initialize tax to 0
      checkout: "",
      checkin: "",
      templatedisplay: "",
      booking_portal_type: null,
      optionTime: "",
      roomCost: 0,
      mealCost: 0,
      mealSelling: 0,
      roomSelling: 0,
      roomNumber: 1,
      guestNumber: "" || 1,
      toggle_form_meal: true,
      toggle_form_data: true,
      value: null,
      options: ["list", "of", "options"],
      bookId: this.$route.params.id,
      optionDate: "",
      check_out_date: "",
      optionsSupplies: [
        {
          id: 1,
          title: "Hotel Director",
        },
        {
          id: 2,
          title: "Contract",
        },
        {
          id: 3,
          title: "Supplier",
        },
      ],
      supplier: "Hotel",
      suppliers: [],
      supplierValue: null,
      contractValue: null,
      meals: [],
      item: null,
      items: [
        {
          status: 0,
          title: this.$t("pending"),
        },
        {
          status: 1,
          title: this.$t("Tentitive"),
        },
        {
          status: 2,
          title: this.$t("confirmed"),
        },
        {
          status: 3,
          title: this.$t("canceled"),
        },
      ],
      mealsValue: "" || "R.O",
      rooms: [],
      hotels: [],
      hotelValue: "",
      roomValue: {
        id: "",
        title: "",
        max_adt: "",
      },
      addedRooms: [
        {
          filteredDays: [],
          selectedRadio: "",
        },
      ],
      roomsCost: "",
      roomsSelling: "",
      offlinebookingCheckIn: "",
      offlinebookingCheckOut: "",
      contracts: [],
      rateCodes: [],
      toggle_custom_price: false,
      rateCode: null,
      roomByRateCode: [],
      roomByRateCodeValue: null,
      totalPriceOffline: 0, // This will hold the calculated total price for offline cost
      totalSellingOffline: 0, // This will hold the calculated total selling price for offline cost
      // filteredDays: [],
      hotelSubmit: true,
      indexOfContract: "",
      loader: false,
      nationality_id: "",
    };
  },
  watch: {
    offlineRooms(newValue) {
      if (newValue) {
        this.booking_portal_type = newValue.booking_portal_type;
        this.checkin = newValue.check_in_date;
        this.checkout = newValue.check_out_date;
        this.addedRooms = newValue.rooms;
        this.hotels = newValue.hotels;
        // console.log(this.hotels);
        this.posted = newValue.lock_status;
        this.day_number = newValue.day_number;
        this.nationality_id = newValue.nationality?.id;

        if (newValue.hotels?.length == 1) {
          this.hotelValue = {
            hotel_name: newValue.hotels[0]?.hotel_name,
            hotel_id: newValue.hotels[0]?.hotel_id,
            consumer_id: newValue.hotels[0]?.consumer_id,
          };
        } else {
          this.hotelValue = "";
        }

        // this.getContracts();
        // console.log(this.addedRooms, "addedRooms");
        this.addedRooms.forEach((room) => {
          room.filteredDays = [];
        });
        //console.log(this.addedRooms, "addedRooms");

        this.offlinebookingCheckOut = new Date(newValue.check_out_date);
        this.offlinebookingCheckOut.setDate(
          this.offlinebookingCheckOut.getDate() + 1,
        );
        this.check_out_date = new Date(newValue.check_out_date);
        this.offlinebookingCheckIn = new Date(newValue.check_in_date);
        // console.log(this.offlinebookingCheckIn, "offlinebookingCheckIn");
        // console.log(this.offlinebookingCheckOut, "offlinebookingCheckOut");
        this.loading = false;
      }
    },
    toggle_form_tax(newValue) {
      // Update the tax variable based on the checkbox state
      this.tax = newValue ? 1 : 0;
    },
    roomCost: "calculateOfflinePrices",
    roomNumber: "calculateOfflinePrices",
    mealCost: "calculateOfflinePrices",
    roomSelling: "calculateOfflinePrices",
    mealSelling: "calculateOfflinePrices",
    guestNumber: "calculateOfflinePrices",
    toggle_form_meal: "calculateOfflinePrices",
    roomValue(newValue) {
      if (newValue) {
        this.guestNumber = newValue.max_adt;
      }
    },
    roomByRateCodeValue(newValue) {
      if (newValue) {
        this.guestNumber = newValue.number_of_adults;
      }
    },
    mealsValue(newValue) {
      if (newValue) {
        this.mealCost = 0;
        this.mealSelling = 0;
      }
    },
  },
  methods: {
    getContracts() {
      if (this.supplier.title == "Contract") {
        this.fetchContracts();
      }
      if (this.supplier.title == "Supplier") {
        this.fetchRooms();
        this.fetchSupplires();
      }
      if (this.supplier.title == "Hotel Director") {
        this.fetchHotel();
      }
      this.indexOfContract = null;
    },
    fetchOffline() {
      this.$emit("roomAdded");
    },
    fetchHotel() {
      console.log(this.hotelValue);
      axios
        .post("checkOrganizationHotel", { hotel_id: this.hotelValue.hotel_id })
        .then((res) => {
          if (res.data.data == false) {
            Swal.fire({
              title: this.$t("this_hotel_has_no_supplers"),
              icon: "error",
              confirmButtonText: this.$t("ok"),
            });
            this.hotelSubmit = false;
          } else {
            this.fetchRooms();
          }
        });
    },
    updateFilteredDays(index) {
      const startDate = new Date(this.addedRooms[index].check_in_date);
      const endDate = new Date(this.addedRooms[index].check_out_date);

      this.addedRooms[index].filteredDays = this.addedRooms[
        index
      ].offline_booking_room_days.filter((day) => {
        const currentDay = new Date(day.day);
        return currentDay >= startDate && currentDay <= endDate;
      });
    },
    formatHotelLabel(hotel) {
      return `${hotel.hotel_name} (${hotel.hotel_id})`;
    },
    updateAllRoomsCost(index) {
      for (
        let item = 0;
        item < this.addedRooms[index].offline_booking_room_days.length;
        item++
      ) {
        // const day = this.addedRooms[index].offline_booking_room_days[item];

        // Check the selected radio button and update accordingly
        if (this.addedRooms[index].selectedRadio == "all") {
          // console.log(this.roomsCost, "roomsCost");
          if (this.toggle_form_data) {
            this.addedRooms[index].offline_booking_room_days[item].room_cost =
              this.roomsCost;
          } else {
            this.addedRooms[index].offline_booking_room_days[item].room_cost =
              this.roomsCost /
              this.addedRooms[index].offline_booking_room_days.length;
          }
        } else if (
          this.addedRooms[index].selectedRadio == "weekday" &&
          this.addedRooms[index].offline_booking_room_days[item].is_weekend ===
            0
        ) {
          if (this.toggle_form_data) {
            this.addedRooms[index].offline_booking_room_days[item].room_cost =
              this.roomsCost;
          } else {
            let filteredArray = this.addedRooms[
              index
            ].offline_booking_room_days.filter((item) => item.is_weekend === 0);
            let lengthOfFilteredArray = filteredArray.length;
            this.addedRooms[index].offline_booking_room_days[item].room_cost =
              Math.round(this.roomsCost / lengthOfFilteredArray);
          }
        } else if (
          this.addedRooms[index].selectedRadio == "weekend" &&
          this.addedRooms[index].offline_booking_room_days[item].is_weekend ===
            1
        ) {
          if (this.toggle_form_data) {
            this.addedRooms[index].offline_booking_room_days[item].room_cost =
              this.roomsCost;
          } else {
            let filteredArray = this.addedRooms[
              index
            ].offline_booking_room_days.filter((item) => item.is_weekend === 1);
            let lengthOfFilteredArray = filteredArray.length;

            this.addedRooms[index].offline_booking_room_days[item].room_cost =
              Math.round(this.roomsCost / lengthOfFilteredArray);
          }
        }
      }
    },
    updateAllRoomsSelling(index) {
      // console.log(this.roomsSelling, "roomsSelling");
      for (
        let item = 0;
        item < this.addedRooms[index].offline_booking_room_days.length;
        item++
      ) {
        // const day = this.addedRooms[index].offline_booking_room_days[item];

        // Check the selected radio button and update accordingly
        if (this.addedRooms[index].selectedRadio == "all") {
          if (this.toggle_form_data) {
            this.addedRooms[index].offline_booking_room_days[
              item
            ].room_selling = this.roomsSelling;
          } else {
            this.addedRooms[index].offline_booking_room_days[
              item
            ].room_selling =
              this.roomsSelling /
              this.addedRooms[index].offline_booking_room_days.length;
          }
        } else if (
          this.addedRooms[index].selectedRadio == "weekday" &&
          this.addedRooms[index].offline_booking_room_days[item].is_weekend ===
            0
        ) {
          if (this.toggle_form_data) {
            this.addedRooms[index].offline_booking_room_days[
              item
            ].room_selling = this.roomsSelling;
          } else {
            // Filter the array to get objects where is_weekend is 0
            let filteredArray = this.addedRooms[
              index
            ].offline_booking_room_days.filter((item) => item.is_weekend === 0);

            // Get the length of the filtered array
            let lengthOfFilteredArray = filteredArray.length;
            console.log(lengthOfFilteredArray, "lengthOfFilteredArray");
            this.addedRooms[index].offline_booking_room_days[
              item
            ].room_selling = Math.round(
              this.roomsSelling / lengthOfFilteredArray,
            );
          }
        } else if (
          this.addedRooms[index].selectedRadio == "weekend" &&
          this.addedRooms[index].offline_booking_room_days[item].is_weekend ===
            1
        ) {
          if (this.toggle_form_data) {
            this.addedRooms[index].offline_booking_room_days[
              item
            ].room_selling = this.roomsSelling;
          } else {
            // Filter the array to get objects where is_weekend is 1
            let filteredArray = this.addedRooms[
              index
            ].offline_booking_room_days.filter((item) => item.is_weekend === 1);

            // Get the length of the filtered array
            let lengthOfFilteredArray = filteredArray.length;
            console.log(lengthOfFilteredArray, "lengthOfFilteredArray");
            this.addedRooms[index].offline_booking_room_days[
              item
            ].room_selling = Math.round(
              this.roomsSelling / lengthOfFilteredArray,
            );
          }
        }
      }
    },
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("offline_booking_room_id", this.addedRooms[index].id);
          formData.append("offline_booking_id", this.bookId);
          axios
            .post("/delete_offline_booking_room", formData)
            .then(({ data }) => {
              this.$emit("roomAdded");
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.addedRooms.splice(index, 1);
                this.fetchContracts();
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            })
            .catch((error) => {
              console.error("Error in network request:", error);
            });
        }
      });
    },
    async fetchSupplires() {
      axios
        .post("/fetch_consumer_by_role", { role: 2, per_page: 0, active: 1 })
        .then(({ data }) => {
          this.suppliers = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    formatSupplierLabel(supplier) {
      return `${supplier.name} (${supplier.id})`;
    },
    editRoom() {
      this.$emit("roomAdded");
    },

    async fetchMealsTypes() {
      axios
        .post("/fetch_meal_types")
        .then(({ data }) => {
          this.meals = data.data.data;
          this.meals.forEach((meal) => {
            if (meal.is_default === 1) {
              this.mealsValue = meal;
            }
          });
          // console.log(this.meals , "meals");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    async fetchRooms() {
      this.hotelSubmit = true;
      const requestData = {
        hotel_id: this.hotelValue.hotel_id,
        // Add other properties as needed
      };

      if (requestData.hotel_id) {
        try {
          const { data } = await axios.post(
            "fetch_room_view_for_hotel",
            requestData,
          );
          this.rooms = data.data;
          // console.log(this.rooms , "rooms");
        } catch (error) {
          Swal.fire({
            title: "",
            text: error.response.data.message || "Unknown error occurred",
            icon: "error",
          });
        }
      }
    },
    fetchMealByRateCode(rateCode) {
      axios
        .post("fetchRateCodeMealTypes", {
          rate_code_id: rateCode.id,
          check_in: moment(this.offlinebookingCheckIn).format("YYYY-MM-DD"),
          check_out: moment(this.check_out_date).format("YYYY-MM-DD"),
        })
        .then((res) => {
          this.mealsRateCode = res.data.data;
        });
    },
    async fetchContracts() {
      this.hotelSubmit = true;
      const requestData = {
        hotel_id: this.hotelValue.hotel_id,
        from_date: moment(this.offlineRooms.check_in_date).format("YYYY-MM-DD"),
        to_date: moment(this.offlineRooms.check_out_date).format("YYYY-MM-DD"),
        // Add other properties as needed
      };
      try {
        const { data } = await axios.post(
          "fetch_contract_by_hotel_id",
          requestData,
        );
        this.contracts = data.data;
        // console.log(this.contracts);
      } catch (error) {
        // console.log(error);
      }
    },
    updateContractValue(contractValue) {
      const requestData = {
        contract_id: contractValue.id,
        start_date: moment(this.checkin).format("YYYY-MM-DD"),
        end_date: moment(this.checkout).format("YYYY-MM-DD"),
        nationality_id: this.nationality_id,
      };
      axios
        .post(`get_rate_code_from_contract_id_with_date_offline`, requestData)
        .then((res) => {
          this.rateCodes = res.data.data;
          const index = this.contracts.findIndex(
            (contract) => contract.id === contractValue.id,
          );
          this.fetchRateCodes(index);
          // console.log(this.rateCodes, "rateCodes");
        })
        .catch((err) => console.log(err));
    },
    fetchRateCodes(index) {
      // console.log('Index:', index);
      this.indexOfContract = index;
    },
    noShow(date) {
      // console.log(date);
      const inputDate = new Date(date);
      const currentDate = new Date();
      if (inputDate <= currentDate) {
        return true;
      } else {
        return false;
      }
    },
    noShowRoom(room, status) {
      axios
        .post("/update_show_offline_booking_room", {
          offline_booking_room_id: room.id,
          is_show: status,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.data.is_show == 1) {
            Swal.fire({
              icon: "success",
              text: this.$t("client has been Arrived"),
              timer: 2000,
            });
          } else if (res.data.data.is_show == 0) {
            Swal.fire({
              icon: "error",
              text: this.$t("client has been Not Arrived"),
              timer: 2000,
            });
          }
          this.$emit("roomAdded");
        });
    },
    disabledInputs(day) {
      // console.log(day);
      const date = new Date(day.day === undefined ? day.date : day.day);
      return date <= new Date();
    },
    formatDate(value) {
      return moment(value).format("DD- MM");
    },
    getWeekday(date) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
      return weekdays[dayIndex];
    },
    fetchRoomByRateCode(rateCode) {
      if (rateCode == null) {
        Swal.fire({
          title: "",
          text: "please select rate code",
          icon: "warning",
        });
      } else {
        const requestData = {
          rate_code_id: rateCode?.id,
          is_contract: 1,
          check_in: moment(this.offlinebookingCheckIn).format("YYYY-MM-DD"),
          check_out: moment(this.check_out_date).format("YYYY-MM-DD"),
          // selling_type => B2B = 1; B2C = 2;
          selling_type: this.type_price_contract ? 1 : 2,
        };
        axios
          .post(`fetch_rooms_by_rate_code_id`, requestData)
          .then((res) => {
            this.roomByRateCode = res.data.data;
            // console.log(this.roomByRateCode, "roomByRateCode");
          })
          .catch((err) => console.log(err));
        this.fetchMealByRateCode(rateCode);
      }
    },
    toggle() {
      this.menu != this.menu;
    },
    async addRoom() {
      this.loader = true;
      const formData = new FormData();
      formData.append("supplier_type_id", this.supplier.id);
      formData.append("hotel_id", this.hotelValue.hotel_id);
      // if (this.supplierValue) {
      // }
      //supplier
      if (this.supplier.id == 3) {
        if (this.supplierValue) {
          formData.append("supplier_id", this.supplierValue.id);
        }
        formData.append("hotel_room_view_id", this.roomValue.id);
        formData.append("supplier_type", 3);
      }
      //contarct
      if (this.supplier.id == 2) {
        formData.append("contract_id", this.contractValue.id);
        formData.append("season_rate_code_id", this.rateCode.id);
        formData.append("room_id", this.roomByRateCodeValue.id);
        formData.append(
          "hotel_room_view_id",
          this.roomByRateCodeValue.hotel_room_view_id,
        );
        formData.append("is_contract_price", this.toggle_custom_price ? 2 : 1);
        formData.append("supplier_type", 2);
        if (this.mealRateCode.id != undefined) {
          formData.append("meal_type_id", this.mealRateCode.meal_type_id);
        }
        if (this.mealCost != undefined) {
          formData.append("meal_cost", this.mealCost);
        }
        if (this.mealSelling != undefined) {
          formData.append("meal_selling", this.mealSelling);
        }
      }
      //hotel
      if (this.supplier.id == 1) {
        console.log(this.hotelValue);
        formData.append("supplier_id", this.hotelValue.consumer_id);
        formData.append("hotel_room_view_id", this.roomValue.id);
        formData.append("supplier_type", 1);
      }
      formData.append("offline_booking_hotel_id", this.bookId);
      // this.hotelValue.id
      if (this.optionDate) {
        formData.append(
          "supplier_option_date",
          moment(this.optionDate).format("YYYY-MM-DD"),
        );
      }
      if (this.optionTime) {
        formData.append(
          "supplier_option_time",
          moment(this.optionTime).format("hh:mm:ss"),
        );
      }
      formData.append(
        "check_out_date",
        moment(this.checkout).format("YYYY-MM-DD"),
      );
      formData.append(
        "check_in_date",
        moment(this.checkin).format("YYYY-MM-DD"),
      );
      formData.append("with_tax", this.tax);
      formData.append("contains_meal", 1);
      if (this.mealsValue) {
        if (this.mealsValue.id != undefined) {
          formData.append("meal_type_id", this.mealsValue.id);
        }
        if (this.mealCost != undefined && this.mealsValue.is_default !== 1) {
          formData.append("meal_cost", this.mealCost);
        }
        if (this.mealSelling != undefined && this.mealsValue.is_default !== 1) {
          formData.append("meal_selling", this.mealSelling);
        }
      }
      if (this.roomCost) {
        formData.append("room_cost", this.roomCost);
      } else if (this.roomByRateCodeValue?.days[0]?.cost != undefined) {
        formData.append("room_cost", this.roomByRateCodeValue?.days[0]?.cost);
      }
      if (this.roomSelling) {
        formData.append("room_selling", this.roomSelling);
      } else if (this.roomByRateCodeValue?.days[0]?.b2b_price != undefined) {
        formData.append(
          "room_selling",
          this.type_price_contract
            ? this.roomByRateCodeValue?.days[0]?.b2b_price
            : this.roomByRateCodeValue?.days[0]?.b2c_price,
        );
      }
      formData.append(
        "guest_number",
        this.guestNumber == "" ? this.roomValue.max_adt : this.guestNumber,
      );
      formData.append("room_number", this.roomNumber);
      formData.append("hotel_reference", this.hotel_reference);
      formData.append("supplier_reference", this.supplier_reference);
      formData.append("status", 1);
      // selling_type => B2B = 1; B2C = 2;
      formData.append("selling_type", this.type_price_contract ? 1 : 2);
      // console.log(this.offlineRooms.number_of_available_rooms);
      // console.log(this.offlineRooms.rooms.length);
      // if (
      //   this.offlineRooms.number_of_available_rooms >
      //   this.offlineRooms.rooms.length
      // ) {
      // } else {
      //   Swal.fire({
      //     title: "",
      //     text: this.$t("no_available_rooms"),
      //     icon: "error",
      //     confirmButtonText: this.$t("ok"),
      //   });
      // }
      await axios
        .post("/offline_booking_room", formData)
        .then(({ data }) => {
          this.$store.state.roomCost = this.roomCost;
          this.$store.state.roomSelling = this.roomSelling;
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.show = false;
          this.supplierValue = null;
          this.supplier = {
            id: 1,
            title: "Hotel Director",
          };
          this.roomValue = null;
          this.contractValue = null;
          this.rateCode = null;
          this.roomByRateCodeValue = null;
          this.optionDate = null;
          this.optionTime = null;
          this.contracts = [];
          this.checkin = null;
          this.checkout = null;
          this.roomCost = null;
          this.roomSelling = null;
          this.mealCost = null;
          this.mealSelling = null;
          this.mealsValue = "R.O";
          this.guestNumber = 1;
          this.roomNumber = 1;
          this.addedRooms.push(data.data);
          this.addedRooms.forEach((room) => {
            room.filteredDays = [];
          });
          this.toggle_form_meal = true;
          this.$emit("roomAdded");
          // this.fetchContracts();
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    updateDaysSelling(room) {
      // console.log(room);
      room.offline_booking_room_days.forEach((day) => {
        // console.log(day);
        day.room_selling = room.room_selling;
        this.$store.state.roomSelling = room.room_selling;
      });
    },
    updateDaysCost(room) {
      // console.log(room);
      room.offline_booking_room_days.forEach((day) => {
        // console.log(day);
        day.room_cost = room.room_cost;
        this.$store.state.roomCost = room.room_cost;
      });
    },
    updateRoom(index) {
      const params = new URLSearchParams();
      // params.append("supplier_type_id", 1);
      params.append("hotel_id", this.addedRooms[index].hotel_id);

      //supplier
      params.append("offline_booking_hotel_id", this.$route.params.id);
      if (this.addedRooms[index].supplier_type == "HOTEL") {
        params.append("supplier_type", 1);
        params.append(
          "hotel_room_view_id",
          this.addedRooms[index].hotel_room_view_id,
        );
      }
      //contarct
      if (this.addedRooms[index].supplier_type == "CONTRACT") {
        params.append("supplier_type", 2);
      }
      if (this.addedRooms[index].supplier_type == "SUPPLIER") {
        params.append("supplier_type", 3);
      }
      if (this.addedRooms[index].supplier) {
        params.append("supplier_id", this.addedRooms[index].supplier.id);
      }
      if (this.addedRooms[index].contract == null) {
        params.append(
          "hotel_room_view_id",
          this.addedRooms[index].hotel_room_view_id,
        );
      }
      if (this.addedRooms[index].contract != null) {
        params.append("contract_id", this.addedRooms[index].contract.id);
        params.append("room_id", this.addedRooms[index].room.id);
      }
      params.append("offline_booking_room_id", this.addedRooms[index].id);
      // this.hotelValue.id
      if (this.addedRooms[index].supplier_option_date) {
        params.append(
          "supplier_option_date",
          moment(this.addedRooms[index].supplier_option_date).format(
            "YYYY-MM-DD",
          ),
        );
      }
      if (this.addedRooms[index].supplier_option_time) {
        params.append(
          "supplier_option_time",
          moment(this.addedRooms[index].supplier_option_time).format(
            "hh:mm:ss",
          ),
        );
      }
      params.append(
        "check_in_date",
        moment(this.addedRooms[index].check_in_date).format("YYYY-MM-DD"),
      );
      params.append(
        "check_out_date",
        moment(this.addedRooms[index].check_out_date).format("YYYY-MM-DD"),
      );
      params.append("with_tax", this.addedRooms[index].tax);
      params.append("contains_meal", 1);
      if (this.addedRooms[index].meal_type !== null) {
        params.append(
          "meal_type_id",
          this.addedRooms[index].meal_type.meal_type_id,
        );
        if (this.addedRooms[index].meal_cost != null) {
          params.append("meal_cost", this.addedRooms[index].meal_cost);
          params.append("meal_selling", this.addedRooms[index].meal_selling);
        }
      }
      if (this.addedRooms[index].with_tax == "WithoutTax") {
        params.append("with_tax", 0);
      } else {
        params.append("with_tax", 1);
      }
      params.append("room_cost", this.addedRooms[index].room_cost);
      // this.$store.state.roomCost = this.addedRooms[index].room_cost;

      if (this.addedRooms[index].contract == "contract") {
        params.append("meal_selling", this.addedRooms[index].meal_selling);
        params.append("meal_cost", this.addedRooms[index].meal_cost);
      }
      if (this.addedRooms[index].season_rate_code !== null) {
        params.append(
          "season_rate_code_id",
          this.addedRooms[index].season_rate_code.id,
        );
      }
      params.append("room_selling", this.addedRooms[index].room_selling);
      this.$store.state.room_selling = this.addedRooms[index].room_selling;

      params.append("guest_number", this.addedRooms[index].guest_number);
      params.append("room_number", this.addedRooms[index].room_number);
      if (this.addedRooms[index].hotel_reference != null) {
        params.append(
          "hotel_reference",
          this.addedRooms[index].hotel_reference,
        );
      }
      if (this.addedRooms[index].supplier_reference != null) {
        params.append(
          "supplier_reference",
          this.addedRooms[index].supplier_reference,
        );
      }
      if (this.addedRooms[index].filteredDays.length > 0) {
        for (
          let item = 0;
          item < this.addedRooms[index].filteredDays.length;
          item++
        ) {
          params.append(
            `days[${item}][day]`,
            this.addedRooms[index].filteredDays[item].day,
          );
          params.append(
            `days[${item}][room_cost]`,
            this.addedRooms[index].filteredDays[item].room_cost,
          );
          params.append(
            `days[${item}][room_selling]`,
            this.addedRooms[index].filteredDays[item].room_selling,
          );
        }
      } else {
        for (
          let item = 0;
          item < this.addedRooms[index].offline_booking_room_days.length;
          item++
        ) {
          params.append(
            `days[${item}][day]`,
            this.addedRooms[index].offline_booking_room_days[item].day,
          );
          params.append(
            `days[${item}][room_cost]`,
            this.addedRooms[index].offline_booking_room_days[item].room_cost,
          );
          params.append(
            `days[${item}][room_selling]`,
            this.addedRooms[index].offline_booking_room_days[item].room_selling,
          );
        }
      }
      // params.append("days", this.addedRooms[index].offline_booking_room_days);
      params.append("status", 1);
      axios
        .put("/update_offline_booking_room", params)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$emit("roomAdded");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    calculateOfflinePrices() {
      if (this.toggle_form_meal) {
        const totalCost = this.roomCost * this.roomNumber * this.day_number;

        const totalSelling =
          this.roomSelling * this.roomNumber * this.day_number;

        this.totalPriceOffline = totalCost;
        this.totalSellingOffline = totalSelling;
      } else {
        const totalCost =
          this.roomCost * this.roomNumber * this.day_number +
          this.mealCost *
            this.roomNumber *
            this.day_number *
            (this.guestNumber === ""
              ? this.roomValue.max_adt
              : parseInt(this.guestNumber));

        const totalSelling =
          this.roomSelling * this.roomNumber * this.day_number +
          this.mealSelling *
            this.day_number *
            this.roomNumber *
            (this.guestNumber === ""
              ? this.roomValue.max_adt
              : parseInt(this.guestNumber));

        this.totalPriceOffline = totalCost;
        this.totalSellingOffline = totalSelling;
      }
    },
    confirmRoom(
      id,
      bookStatus,
      supplier_option_date,
      supplier_option_time,
      room,
    ) {
      //console.log(room, "room");
      if ((bookStatus == 1 && supplier_option_date == null) || undefined) {
        Swal.fire({
          title: this.$t("you_must_change_room_option_data"),
          icon: "warning",
        });
        room.book_status = null;
      } else {
        Swal.fire({
          title: this.$t("change_room_status"),
          text: this.$t("are_you_sure_change_room_status"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm"),
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("offline_booking_room_id", id);
            formData.append("book_status", bookStatus);
            if (supplier_option_date) {
              formData.append(
                "supplier_option_date",
                moment(supplier_option_date).format("YYYY-MM-DD"),
              );
            }
            if (supplier_option_time) {
              formData.append("supplier_option_time", supplier_option_time);
            }
            axios
              .post("/Confirm_Room_Status", formData)
              .then((res) => {
                Swal.fire("", res.data.message, "success");
                // console.log(res.data);
                this.$emit("roomAdded");
                // this.fetchHotel();
              })
              .catch((error) => {
                Swal.fire({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                });
              });
          }
        });
      }
    },
    updateDayNumber() {
      if (this.checkin && this.checkout) {
        const checkinDate = new Date(this.checkin);
        const checkoutDate = new Date(this.checkout);
        const diffTime = checkoutDate - checkinDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.day_number = Math.max(diffDays, 0);
        this.calculateOfflinePrices();
      }
    },
    updateGuestNumber() {
      this.guestNumber = this.roomValue?.max_adt ?? 1;
    },
    removeMeals() {
      this.mealsValue = "";
      this.mealRateCode = "";
      this.meals.forEach((meal) => {
        if (meal.is_default === 1) {
          this.mealsValue = meal;
        }
      });
    },
  },
  async mounted() {
    // await this.fetchSupplires();
    await this.fetchMealsTypes();
  },
  computed: {
    disabledDates() {
      if (this.addedRooms.length > 0) {
        const fromDate = new Date();
        const toDate = new Date(this.checkin);
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate());
        return {
          to: fromDate,
          from: toDate,
        };
      } else {
        const fromDate = new Date();
        const toDate = new Date(this.checkin);
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate());
        return {
          to: fromDate,
          from: toDate,
        };
      }
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      checkin: {
        required,
      },
      checkout: {
        required,
      },
      roomSelling: {
        required,
      },
      roomCost: {
        required,
      },
      roomNumber: {
        required,
      },
      guestNumber: {
        required,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "./_availableRooms.scss";
@import "@/view/dashboard/contract/seasons/_season.scss";
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline.scss";
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline.scss";
.vuejs3-datepicker {
  min-width: 135px;
}
// C:\Projects\softTech\src\view\dashboard\contract\seasons\_season.scss

.userData {
  gap: 10px;
  flex-wrap: wrap;

  .form-group {
    flex: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 1012px) {
  .userData {
    flex-direction: column;

    .form-group {
      width: 100%;
    }
  }
  .inputsData {
    flex-direction: column;

    .select {
      width: 100%;
    }
  }
}

.inputsData {
  display: flex;
  flex-wrap: wrap;

  .select {
    flex: 2;
    min-width: 150px;
  }
}

.check-boxes {
  .form-check {
    margin-inline-end: 10px;
  }
}

.headline {
  padding-left: 10px;
  padding-right: 10px;

  .form-group {
    .form-label {
      width: fit-content;
    }
  }
}

.details .data {
  & > div {
    border-left: 1px dashed #aaa;
  }
}

.p-accordion-header {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.add_room {
  background-color: #0a909b;
  font-size: 0.7rem;
  font-weight: bold;
  color: #fff;
}
</style>
